//src/auth/Stripe.ts
import initializeStripe from "./InitializeStripe";
import {User} from "firebase/auth";

export async function isUserPremium(user: User): Promise<boolean> {
    const decodedToken = await user.getIdTokenResult();
    return !!decodedToken?.claims?.stripeRole;
}

export async function createCheckoutSession(sessionId: string) {
    const stripe = await initializeStripe();
    if (stripe) {
        const { error } = await stripe.redirectToCheckout({sessionId: sessionId,});
        if (error) console.log(error)
    }
}