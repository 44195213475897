import React from 'react';
import "../css/GenericPage.css"

export const GoPremiumButton: React.FC<any> = ({isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    if (!isAuthenticated) {
        return (<button className="go-premium-button" onClick={() => setShowAuth(true)}>Join Now</button>);
    } else {
        return (<button className="go-premium-button" onClick={() => setShowPremiumOverlay(true)}>Go Premium</button>);
    }

};

export const LogInButton: React.FC<any> = ({setShowAuth}) => {
    return (
        <button className="log-in-button" onClick={() => setShowAuth(true)}>Log In</button>
    );
};