import React from "react";
import "../css/MobileHamburgerMenu.css"
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import {GoPremiumButton, LogInButton} from "./GoPremiumButton";
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";

export const MobileHamburgerMenu:React.FC<any> = ({setShowHamburger, isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay}) => {
    const navigate = useNavigate()
    return (
        <div className={'mobile-hamburger-container'} onClick={() => setShowHamburger(false)}>
            <div className={'mobile-hamburger-content'}>
                <ICONS.CLOSE className={'hamburger-close-icon'} />
                <div className={'hamburger-options'}>
                    <div className={'hamburger-log-in-and-join-container'}>
                        {!isAuthenticated && <LogInButton setShowAuth={setShowAuth} />}
                        {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
                    </div>
                    <div className={"hamburger-header-link"} onClick={() => navigate("/")}>Home</div>
                    {LEARNING_PATHS.map((item, index) => (
                        <div onClick={() => navigate(item.navUrl)} className={"hamburger-header-link"}>{item.header}</div>
                    ))}
                    {isAuthenticated && <div onClick={() => navigate("/account")} className={"hamburger-header-link"}>Account</div>}
                </div>
            </div>
        </div>
    )
}