// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LandingPage } from "./pages/LandingPage";
import "./css/Global.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { LEARNING_PATHS } from "./constants/LearningPathsConfig";
import { LearningPathPage } from "./pages/LearningPathPage";
import FirebaseAuth from "./util/Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LearningContentPage } from "./pages/LearningContentPage";
import { LessonPage } from "./pages/LessonPage";
import { AccountPage } from "./pages/AccountPage";
import { MobileHamburgerMenu } from "./components/MobileHamburgerMenu";
import initializeStripe from "./util/InitializeStripe";
import ScrollToTop from "./util/ScrollToTop";
import { isUserPremium } from "./util/Stripe";
import {PremiumOverlay} from "./components/PremiumOverlay";

function App() {
    const [showAuth, setShowAuth] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [isCheckingPremium, setIsCheckingPremium] = useState(false);
    const [user, setUser] = useState(null);
    const [showHamburger, setShowHamburger] = useState(false);
    const [showPremiumOverlay, setShowPremiumOverlay] = useState(false);

    useEffect(() => {
        initializeStripe().then(() => {
            console.log('Stripe initialized');
        }).catch((error) => {
            console.error('Error initializing Stripe:', error);
        });

        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setIsAuthenticated(true);
                setShowAuth(false);
            } else {
                setIsAuthenticated(false);
                setIsPremium(false);
                setUser(null);
                setShowPremiumOverlay(false); // Hide premium overlay on logout
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const checkPremiumStatus = async () => {
            if (user) {
                setIsCheckingPremium(true);
                const premiumStatus = await isUserPremium(user);
                setIsPremium(premiumStatus);
                setShowPremiumOverlay(!premiumStatus);
                setIsCheckingPremium(false);
            }
        };
        checkPremiumStatus();
    }, [user]);

    return (
        <Router>
            <ScrollToTop />
            <div className="app-container">
                <Header setShowAuth={setShowAuth} isAuthenticated={isAuthenticated} user={user} setShowHamburger={setShowHamburger} setShowPremiumOverlay={setShowPremiumOverlay}/>
                <div className="page-content">
                    {isCheckingPremium ? (
                        <p>Loading...</p>
                    ) : (
                        <Routes>
                            <Route path="/" element={<LandingPage isAuthenticated={isAuthenticated} isPremium={isPremium} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>} />
                            <Route path="/account" element={<AccountPage />} />
                            {LEARNING_PATHS.map((path, index) => (
                                <Route key={index} path={`${path.navUrl}`} element={<LearningPathPage isAuthenticated={isAuthenticated} isPremium={isPremium} learningPath={path} setShowPremiumOverlay={setShowPremiumOverlay} setShowAuth={setShowAuth}/>} />
                            ))}
                            <Route path='/:learningPath/:contentType/:contentName' element={<LearningContentPage isAuthenticated={isAuthenticated} isPremium={isPremium} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>} />
                            <Route path='/:learningPath/courses/:course/:sectionIdParam/:lessonIdParam' element={<LessonPage isAuthenticated={isAuthenticated} isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay}/>} />
                        </Routes>
                    )}
                </div>
                <Footer />
                {(showAuth && !isAuthenticated) && <FirebaseAuth setShowAuth={setShowAuth} />}
                {showHamburger && <MobileHamburgerMenu setShowHamburger={setShowHamburger} isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
                {showPremiumOverlay && <PremiumOverlay user={user} setShowPremiumOverlay={setShowPremiumOverlay}/>}
            </div>
        </Router>
    );
}

export default App;