import React from "react";
import "../css/Header.css";
import LogoMedium from "../images/Logo_Medium.webp";
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import { RiAccountBoxFill } from "react-icons/ri";
import {GoPremiumButton, LogInButton} from "./GoPremiumButton";
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";

export const Header: React.FC<any> = ({setShowAuth, isAuthenticated, setShowHamburger, setShowPremiumOverlay}) => {
    const navigate = useNavigate()
    return (
        <div className="header-module-container">
            <img className={"header-logo"} src={LogoMedium} alt={"Beyond Code Logo"} onClick={() => navigate("/")}/>
            <div className={"header-links-container"}>
                {LEARNING_PATHS.map((item, index) => (
                    <div className={"header-link-content"} key={item.navUrl}>
                        <div className={"header-link"} onClick={() => navigate(item.navUrl)}>{item.header}</div>
                        {index < LEARNING_PATHS.length - 1 && (
                            <span className="header-divider">|</span>
                        )}
                    </div>
                ))}
            </div>
            {isAuthenticated && <RiAccountBoxFill className={"header-account-icon"} onClick={() => navigate("/account")}/>}
            {!isAuthenticated && (
                <div className={'header-logged-out-buttons'}>
                    <div className={'header-logged-out-web-container'}>
                        <LogInButton setShowAuth={setShowAuth}/>
                        <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>
                    </div>
                    <ICONS.HAMBURGER className="header-mobile-hamburger" onClick={() => setShowHamburger(true)}/>
                </div>
            )}
        </div>
    );
};