import ProgrammingResumesIcon from "../images/Programming Resumes and More Icon.webp";
import ProgrammingResumesHero from "../images/learningPaths/ProgrammingResumeHero.webp";
import TechInterviewPrepIcon from "../images/Tech Interview Icon.webp";
import TechInterviewPrepHero from "../images/learningPaths/TechInterviewPrepHero.webp";
import LearningToCodeIcon from "../images/Learning to Code Icon.webp";
import LearningToCodeHero from "../images/learningPaths/LearningToCodeHero.webp";
import {BECOMING_A_PROGRAMMER_COURSES, PROGRAMMER_RESUMES_COURSES, TECH_INTERVIEW_PREP_COURSES} from "./CoursesConfig";
import {INTERVIEWS} from "./InterviewsConfig";
import {RESOURCES} from "./ResourcesConfig";
import {ARTICLES} from "./ArticlesConfig";
import {ADVICE} from "./AdviceConfig";

export const LEARNING_PATHS = [
    {
        header: "Becoming A Programmer",
        subtitle: "First things first: Let's learn what programming is and, more importantly, if you should become a programmer...",
        navUrl: "/learning-to-code/",
        icon: LearningToCodeIcon,
        heroImage: LearningToCodeHero,
        cssId: 'learning-to-code',
        pageHeader: 'Helping You',
        pageHeaderSpan: 'Become A Programmer',
        courseHeader: 'Courses for Becoming a Programmer',
        courses: BECOMING_A_PROGRAMMER_COURSES,
        interviewHeader: 'Interviews from Software Engineers',
        interviews: INTERVIEWS.becomingAProgrammer,
        resourceHeader: 'Resources for New Developers',
        resources: RESOURCES.becomingAProgrammer,
        adviceHeader: 'Expert Advice on Learning to Code',
        advice: ADVICE.becomingAProgrammer,
        articlesHeader: 'Articles',
        articles: ARTICLES.becomingAProgrammer
    },
    {
        header: "Programmer Resumes And More",
        subtitle: "About to start your job search? Learn how to stand out as an applicant and line up interviews",
        navUrl: "/write-a-tech-resume-and-more/",
        icon: ProgrammingResumesIcon,
        heroImage: ProgrammingResumesHero,
        cssId: 'programming-resumes',
        pageHeader: 'Helping You Write Your',
        pageHeaderSpan: 'Tech Resume, Cover Letter, & More',
        courseHeader: 'Resume Building Courses',
        courses: PROGRAMMER_RESUMES_COURSES,
        interviewHeader: 'Interviews About Job Searching',
        interviews: INTERVIEWS.programmerResumes,
        resourceHeader: 'Resources for Writing A Programmer Resume',
        resources: RESOURCES.programmerResumes,
        adviceHeader: 'Expert Advice on Tech Resumes',
        advice: ADVICE.techInterviews,
        articlesHeader: 'Articles',
        articles: ARTICLES.programmerResumes
    },
    {
        header: "Tech Interview Prep",
        subtitle: "Tech interviews can be tricky, but they don't have to be. Learn the best way to prepare and land that job offer",
        navUrl: "/tech-interview-prep/",
        icon: TechInterviewPrepIcon,
        heroImage: TechInterviewPrepHero,
        cssId: 'tech-interview-prep',
        pageHeader: 'Helping You Prepare For',
        pageHeaderSpan: 'Tech Interviews',
        courseHeader: 'Courses for Tech Interviews',
        courses: TECH_INTERVIEW_PREP_COURSES,
        interviewHeader: 'Mock Programmer Interviews',
        interviews: INTERVIEWS.techInterviewPrep,
        resourceHeader: 'Resources to Ace Your Interview',
        resources: RESOURCES.programmerResumes,
        adviceHeader: 'Interviewing Advice From Experts',
        advice: ADVICE.techInterviews,
        articlesHeader: 'Articles',
        articles: ARTICLES.programmerResumes
    }
]