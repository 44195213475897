import React from 'react';
import '../css/HeroImage.css';
import '../css/LearningPathHeroImage.css'
import {GoPremiumButton} from "./GoPremiumButton";

export const LearningPathHeroImage: React.FC<any> = ({learningPath, isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    return (
        <div className="learning-path-hero-image-container">
            <div className="learning-path-hero-text-container">
                <div className="learning-path-hero-text">
                    <h1>{learningPath.pageHeader}</h1>
                    <span className="highlighted">{learningPath.pageHeaderSpan}</span>
                    {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
                </div>
                <img src={learningPath.heroImage} alt={`${learningPath.name} icon`}/>
            </div>
        </div>
    );
};