import React, {useEffect, useState} from 'react';
import "../css/CoursePage.css"
import {useNavigate, useParams} from "react-router-dom";
import {ICONS} from "../util/IconHelper";
import {Course} from "../models/Course";
import {getContentFromParams} from "../util/ContentHelper";
import {GridItemIconInfo} from "../components/LearningPathContent";
import {Interview} from "../models/Interview";
import {Resource} from "../models/Resource";
import {GenericContent} from "../models/GenericContent";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../util/Firebase";
import VideoPlayer from "../components/VideoPlayer";
import {Article} from "../models/Article";

enum PageType {
    COURSE = "Course",
    INTERVIEW = "Interview",
    RESOURCE = "Resource",
    ARTICLE = "Article"
}

enum LOCK_ICON {
    PLAY_BUTTON,
    UNLOCK_ICON
}

enum CONTENT_CLICK_TYPE {
    START_COURSE,
    LESSON_CLICKED,
    PLAY_INTERVIEW,
    UNLOCK_RESOURCE
}

function getPageType(contentType: String | undefined): PageType {
    switch (contentType) {
        case 'courses': return PageType.COURSE
        case 'interviews': return PageType.INTERVIEW
        case 'resources': return PageType.RESOURCE
        case 'articles': return PageType.ARTICLE
    }
    return PageType.COURSE
}

export const LearningContentPage: React.FC<any> = ({isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay}) => {
    const {learningPath, contentType, contentName} = useParams();
    const pageType = getPageType(contentType)
    const navigate = useNavigate();
    let contentData
    if (learningPath != null && contentType != null && contentName != null) {
        contentData = getContentFromParams(learningPath, contentType, contentName)
    }

    const onAccessContentClicked = (clickType: CONTENT_CLICK_TYPE, navUrl = "") => {
        if (!isAuthenticated) {
            setShowAuth(true)
        } else if (!isPremium) {
            setShowPremiumOverlay(true)
        } else {
            switch (clickType) {
                case CONTENT_CLICK_TYPE.LESSON_CLICKED:
                    navigate(navUrl)
                    break
            }

        }
    }

    return (
        <div className="page-container">
            {contentData && (
                <div className={'course-page-container'}>
                    <div className="course-page-header">
                        {(pageType !== PageType.INTERVIEW && pageType !== PageType.RESOURCE) &&
                            <img src={contentData.thumbnail} alt={`${contentData.name} icon`}/>
                        }
                        <div className="course-page-info">
                            <p>{pageType}</p>
                            <h3>{contentData.name}</h3>
                            <h4>{contentData.description}</h4>
                            {pageType === PageType.COURSE && <CourseFeatures course={contentData as Course} />}
                            {pageType === PageType.COURSE && !isPremium && <BeginCourseCTA onAccessContentClicked={onAccessContentClicked}/>}
                            <LearningContentIcons content={contentData} type={pageType} />
                        </div>
                    </div>
                    {pageType === PageType.COURSE && <CourseLessons learningPath={learningPath} course={contentData as Course} onAccessContentClicked={onAccessContentClicked} />}
                    {(pageType === PageType.INTERVIEW && isPremium) && <InterviewSection interview={contentData as Interview} isPremium={isPremium} isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
                    {(pageType === PageType.INTERVIEW && !isPremium) && <LockedContent thumbnail={contentData.thumbnail} name={contentData.name} onAccessContentClicked={onAccessContentClicked} lockIcon={LOCK_ICON.PLAY_BUTTON}/>}
                    {(pageType === PageType.RESOURCE && isPremium) && <ResourcePDF resource={contentData as Resource} isAuthenticated={isAuthenticated} isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay}/>}
                    {(pageType === PageType.RESOURCE && !isPremium) && <LockedContent thumbnail={contentData.thumbnail} name={contentData.name} onAccessContentClicked={onAccessContentClicked} lockIcon={LOCK_ICON.UNLOCK_ICON}/>}
                    {pageType === PageType.ARTICLE && <ArticleContent article={contentData as Article} />}
                </div>
            )}
        </div>
    );
};

const CourseFeatures: React.FC<{course: Course}> = ({course}) => {
    return (
        <div className='courses-features-section'>
            <ul>
                {course.keyFeatures.map((item, index) => (
                    <li key={course.name}>{item}</li>
                ))}
            </ul>
        </div>
    )
}

const BeginCourseCTA: React.FC<any> = ({onAccessContentClicked}) => {
    return (<button className={'begin-course-cta'} onClick={() => onAccessContentClicked()}>Start Course</button>)
}

const CourseLessons: React.FC<{ learningPath: string | undefined, course: Course, onAccessContentClicked: any }>
    = ({learningPath, course, onAccessContentClicked}) => {
    return (
        <div className={'course-lessons-list'}>
            <h3>Course Content</h3>
            {course.sections.map((section, index) => (
                <div className={'section-container'}>
                    <h5 className={'section-title'}>{section.title}</h5>
                    {section.lessons.map((lesson, index) => (
                        <div className={'lesson-container'}
                             onClick={() => onAccessContentClicked(lesson.id, section.id, `/${learningPath}/courses/${course.navUrl}/${section.id}/${lesson.id}`)}>
                            <ICONS.SOLID_CIRCLE className={'lesson-icon-not-started'} />
                            <h6>{lesson.title}</h6>
                        </div>

                    ))}
                </div>
            ))}
        </div>
    )
}

const LearningContentIcons: React.FC<{content: GenericContent, type: PageType}> = ({content, type}) => {
    return (
        <div className={"course-page-icons-container"}>
            {type === PageType.COURSE && <GridItemIconInfo icon={ICONS.LESSONS} text={(content as Course).numberOfLessons}/>}
            {type === PageType.COURSE && <GridItemIconInfo icon={ICONS.CLOCK} text={(content as Course).time}/>}
            {type === PageType.INTERVIEW && <GridItemIconInfo icon={ICONS.CLOCK} text={(content as Interview).time}/>}
        </div>
    )
}

const ResourcePDF: React.FC<{
    resource: Resource,
    isAuthenticated: boolean,
    isPremium: boolean,
    setShowPremiumOverlay: any
}> = ({resource, isAuthenticated, isPremium, setShowPremiumOverlay}) => {
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    useEffect(() => {
        const fileRef = ref(storage, `resources/${resource.fileUrl}`);
        getDownloadURL(fileRef).then((url) => {
            setFileUrl(url);
        }).catch((error) => {
            console.error("Error fetching resource URL:", error);
        });
    });
    return (
        <div className='resource-pdf-section'>
            {(isPremium && fileUrl) && (<iframe className={`resource-pdf-content`} src={fileUrl} />)}
        </div>
    )
}

const InterviewSection: React.FC<any> = ({interview, isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    return (
        <div className={`interview-video-container`}>
            {isPremium && <VideoPlayer isPremium={isPremium} fileUrl={`interviews/${interview.fileUrl}`} isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
        </div>
    )
}

const ArticleContent: React.FC<{ article: Article}> = ({article}) => {
    return (
        <div className={"article-content-container"}>
            <div dangerouslySetInnerHTML={{__html: article.content}}/>
        </div>
    )
}

const LockedContent: React.FC<any> = ({thumbnail, name, lockIcon, onAccessContentClicked}) => {
    return (
        <div className={'interview-play-preview'} onClick={() => onAccessContentClicked()}>
            <div className={'image-preview-overlay'}/>
            <img src={thumbnail} alt={`${name} icon`}/>
            <div className={'interview-play-preview-icon-container'}>
                {lockIcon === LOCK_ICON.PLAY_BUTTON && <ICONS.PLAY_CIRCLE className={'play-button-overlay'}/>}
                {lockIcon === LOCK_ICON.UNLOCK_ICON && <ICONS.UNLOCK className={'play-button-overlay'}/>}
            </div>
        </div>
    )
}