import React from 'react';
import '../css/HeroImage.css';
import {GoPremiumButton} from "./GoPremiumButton";

export const HeroImage: React.FC<any> = ({isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    return (
        <div className="hero-image-container">
            <div className="hero-text-container">
                <h1>Helping You Build Your</h1>
                <div className="hero-highlighted-text">
                    <span className="highlighted">Coding</span>
                    <span> Career</span>
                </div>
            </div>
            {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay} />}
        </div>
    );
};