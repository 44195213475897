import React from 'react';
import {HeroImage} from "../components/HeroImage";
import {LearningPathModule} from "../components/LearningPathsModule";
import {MembershipOverviewModule} from "../components/MembershipOverviewModule";
import "../css/GenericPage.css"
import {AboutMeModule} from "../components/AboutMeModule";
import {TestimonialsModule} from "../components/TestimonialsModule";
import {GoPremiumButton} from "../components/GoPremiumButton";

export const LandingPage: React.FC<any> = ({ isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay }) => {
    return (
        <div className="page-container">
            <HeroImage isPremium={isPremium} isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>
            <LearningPathModule/>
            <MembershipOverviewModule/>
            <div className={'premium-button-container'}>
                {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
            </div>
            <AboutMeModule/>
            <TestimonialsModule/>
            <div className={'premium-button-container testimonial'}>
                {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>}
            </div>
        </div>
    );
};