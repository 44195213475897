import React from "react";
import "../css/LearningPathModule.css";
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import { IoIosArrowForward } from "react-icons/io";
import {useNavigate} from "react-router-dom";

export const LearningPathModule = () => {
    const navigate = useNavigate()
    return (
        <div className="learning-path-module-container">
            {LEARNING_PATHS.map((item, index) => (
                <div key={index} className={`path-item ${item.cssId}`} onClick={() => navigate(item.navUrl)}>
                    <div className="path-icon">
                        <img src={item.icon} alt={`${item.header} icon`} />
                    </div>
                    <div className="path-text">
                        <h1>{item.header}</h1>
                        <p>{item.subtitle}</p>

                    </div>
                    <div className="path-learn-more-container">
                        <p>Learn More</p>
                        <IoIosArrowForward className="learn-more-arrow"/>
                    </div>
                </div>
            ))}
        </div>
    );
};