import React, {useEffect, useRef, useState} from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../util/Firebase';
import videojs from "video.js";
import '../css/VideoPlayer.css'

export const VideoPlayer: React.FC<any> = ({ fileUrl, isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay }) => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const videoRef = useRef(null);


    useEffect(() => {
        const videoRef = ref(storage, fileUrl);
        getDownloadURL(videoRef).then((url) => {
            setVideoUrl(url);
        }).catch((error) => {
            console.error("Error fetching video URL:", error);
        });
    });

    useEffect(() => {
        if (videoUrl && videoRef.current) {
            const player = videojs(videoRef.current, {
                controls: true,
                autoplay: false,
                preload: 'auto',
                sources: [{
                    src: videoUrl,
                    type: 'video/mp4'
                }]
            });

            return () => {
                if (player) {
                    player.dispose();
                }
            };
        }
    }, [videoUrl]);

    if (isPremium) {
        return (
            <div className={'video-container'}>
                {videoUrl ? (
                    <video ref={videoRef} className="video-js vjs-default-skin video-player"/>
                ) : (<p>Video not available</p>)}
            </div>
        );
    } else {return (<></>);}
};

export default VideoPlayer;