import {GenericContent} from "./GenericContent";

export class Course extends GenericContent {
    time: string;
    numberOfLessons: number;
    keyFeatures: string[];
    sections: Section[];

    constructor(
        name: string,
        description: string,
        time: string,
        thumbnail: string,
        numberOfLessons: number,
        keyFeatures: string[],
        sections: Section[],
        navUrl: string
    ) {
        super(name, description, thumbnail, navUrl);
        this.time = time;
        this.numberOfLessons = numberOfLessons;
        this.keyFeatures = keyFeatures;
        this.sections = sections;
    }
}

export class Section {
    id: number;
    title: string;
    lessons: Lesson[];

    constructor(id: number, title: string, lessons: Lesson[]) {
        this.id = id;
        this.title = title;
        this.lessons = lessons;
    }
}

export class Lesson {
    title: string;
    navUrl: string;
    fileName: string;
    id: number;

    constructor(id: number, title: string, navUrl: string, fileName: string) {
        this.id = id;
        this.title = title;
        this.navUrl = navUrl;
        this.fileName = fileName;
    }
}